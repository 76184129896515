import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Redirect, useParams } from 'react-router-dom';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import query from './variables.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const FollowUpDeployment = () => {
    const { deploymentHandle } = useParams();
    const [sex, setSex] = useState(null);

    if (!['followup1', 'followup2', 'followup3', 'followup4'].includes(deploymentHandle)) {
        return <Redirect to="/" />;
    }

    if (!Fortress.auth()) {
        return <Redirect to={`?redirectUrl=${Boilerplate.url('/follow-up/' + deploymentHandle)}`} />;
    }

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });

            let sexInt = 0;
            try {
                sexInt = Number(resp.data.roles.anyRole.assignmentProgress.variables.SEX);
                if (Number.isNaN(sexInt)) {
                    sexInt = 0;
                }
            } catch (e) {
                sexInt = 0;
            }

            setSex(sexInt);
        }

        getData();
    }, []);

    if (!sex && sex !== 0) {
        return <Loader loading />;
    }

    return (
        <DeploymentPlayer
            deploymentHandle={deploymentHandle}
            flowProps={{ variables: { sex } }}
            pawnId={Fortress.user.acting.id}
            pawnHash={Fortress.user.acting.random_hash}
        />
    );
};

export default FollowUpDeployment;
