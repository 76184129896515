import React from 'react';
import PropTypes from 'prop-types';
import { NavBar, NavLink } from 'cccisd-laravel-boilerplate';
import style from './style.css';

const Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return (
            <div style={{ marginBottom: '-1.45em' }}>
                <NavBar {...this.props} logo={<div className={style.logo} />}>
                    {Fortress.auth() && Fortress.user.acting.role.handle === 'uberadmin' && (
                        <NavLink to="/manage">Manage</NavLink>
                    )}
                </NavBar>
            </div>
        );
    }
}
