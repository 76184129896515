import React from 'react';
import { Link, Route, useLocation } from 'react-router-dom';
import _find from 'lodash/find';
import { LoginForm } from 'cccisd-laravel-nexus';
import Style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);
    const location = useLocation();

    let redirectLocation;
    try {
        redirectLocation = location.search
            .substring(location.search.lastIndexOf('redirectUrl='))
            .replace('redirectUrl=', '');

        if (!redirectLocation || redirectLocation.length < 2) {
            redirectLocation = Boilerplate.url('/authRedirect');
        }
    } catch (e) {
        redirectLocation = Boilerplate.url('/authRedirect');
    }

    return (
        <div className="landingPage" style={{ marginTop: '1.5em' }}>
            <div className="welcomeLogin">
                <div className="row">
                    <div className="col-md-8">
                        <div>
                            <h1>
                                <b>Welcome to webCHAT</b>
                            </h1>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {!Fortress.auth() && (
                            <Route
                                render={({ history }) => (
                                    <LoginForm
                                        defaultRedirectUrl={redirectLocation}
                                        showPasswordToggle
                                    />
                                )}
                            />
                        )}
                        {Fortress.auth() && (
                            <div className={Style.welcomeBox}>
                                <div>Hello, {Fortress.user.username}</div>
                                {homepageRoute && (
                                    <Link to={homepageRoute.url}>
                                        <button type="button" className="btn btn-primary">
                                            Go To Home
                                        </button>
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
