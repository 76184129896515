import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import NavBar from 'js/components/NavBar';
import { Footer } from 'cccisd-laravel-boilerplate';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showPrimaryNav: PropTypes.bool,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPrimaryNav: true,
        showSecondaryNav: true,
        showFooter: true,
    };

    render() {
        let { children, className, showPrimaryNav, showFooter, showSecondaryNav } = this.props;

        // hardcoding for this app ///////////
        showSecondaryNav = false;
        // ////////////////////////////////////

        return (
            <div className={style.wrapper}>
                {showPrimaryNav && (
                    <div className={style.header}>
                        <NavBar className={this.props.className} />
                    </div>
                )}

                {showSecondaryNav && Fortress.auth() && Fortress.user.acting.role.handle !== 'learner' && (
                    <div className={style.secondaryNav}>
                        <SecondaryNav className={className} navs={AppDefs.navs} routes={AppDefs.routes} />
                    </div>
                )}
                <div className={style.body}>
                    <div className={className}>{children}</div>
                </div>

                {showFooter && (
                    <div className={style.footer}>
                        <Footer className={className} />
                    </div>
                )}
            </div>
        );
    }
}
